.mat-icon {
  color: var(--grey-700);
  width: 16px;
  height: 16px;
}

.mat-icon.mat-primary {
  color: var(--primary-700);
}

.mat-icon.mat-warn {
  color: var(--error-500);
}

// iconos home y navbar
.mat-icon-l {
  width: 24px;
  height: 24px;
}
