.mat-tab-header {
  border: none;
}

.mat-tab-link-container {
  & .mat-tab-link {
    color: var(--primary-700);
    opacity: 1;

    &:hover {
      color: var(--grey-1000);
    }
  }

  & .active-link {
    color: var(--grey-1000);
    border-bottom: none;
  }
}

.mat-tab-label {
  text-transform: uppercase;

  &-active {
    color: var(--grey-1000);
  }
}
