@import 'mixins/main';

.text-danger {
  color: var(--error-500) !important;
}

.text-disabled {
  color: var(--grey-400) !important;
  pointer-events: none;

  & mat-icon {
    color: var(--grey-400) !important;
  }
}

.primary-text {
  color: var(--primary-text);
}

.secondary-text {
  color: var(--secondary-text);
}

// Separador / linea horizontal
hr.divider {
  border: 0;
  border-bottom: 1px solid var(--grey-300);
}

// Para elementos que son clickables y tienen efectos visuales (como hover)
.action {
  cursor: pointer;

  &:hover {
    color: var(--primary-text);
  }
}

.light-text {
  color: var(--primary-400);
}

// Shadows
.shadow-tb {
  @include shadow-tb;
}

.shadow-modal {
  @include shadow-modal;
}

// Para rotar iconos o cosas
.spinner {
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(1, 1, 0, 0);
  animation-iteration-count: infinite;
  animation-play-state: running;
  animation-name: spin-animation;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
