@import '../mixins/fonts';

.mat-table {
  & th {
    @include overline-o2;

    background: var(--primary-100);

    &.center {
      text-align: center;
    }
  }

  & td .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
