$dark-primary-text: #100316;
$dark-secondary-text: #69606D;
$dark-disabled-text: #BEBBC0;
$dark-divider: #D1CFD2;
$dark-focused: #4B4150;
$light-primary-text: #FFFFFF;
$light-secondary-text: #D1CFD2;
$light-disabled-text: #69606D;
$light-divider: #4B4150;
$light-focused: #D1CFD2;

$basic-palette: (
  100: #ECF8F8,
  200: #D8F3F2,
  300: #9EE1DE,
  400: #5DCDC7,
  500: #2CB9B2,
  600: #019D96,
  700: #01837D,
  800: #265D5A,
  900: #004745,
  1000: #003834,
  contrast: (
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    1000: $light-primary-text,
  )
);

$basic-dark-palette: (
  100: #003834,
  200: #004745,
  300: #265D5A,
  400: #01837D,
  500: #019D96,
  600: #2CB9B2,
  700: #5DCDC7,
  800: #9EE1DE,
  900: #D8F3F2,
  1000: #ECF8F8,
  contrast: (
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    1000: $dark-primary-text,
  )
);

$grey-palette: (
  50: #FFFFFF,
  100: #FAFAFA,
  200: #EBEAEB,
  300: #D1CFD2,
  400: #BEBBC0,
  500: #A5A0A7,
  600: #847D87,
  700: #69606D,
  800: #4B4150,
  900: #3A2F3F,
  1000: #2E2233,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    1000: $light-primary-text,
  )
);

$grey-dark-palette: (
  50: #262029,
  100: #18131B,
  200: #262029,
  300: #342D38,
  400: #433C46,
  500: #57505A,
  600: #6B636F,
  700: #897F8E,
  800: #A8A1AC,
  900: #D5D2D7,
  1000: #F0EFF1,
  contrast: (
    50: $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    1000: $dark-primary-text,
  )
);

$yellow-palette: (
  100: #FFF6DB,
  200: #FFE38F,
  300: #FFCD34,
  400: #EDB300,
  500: #CF9C00,
  600: #AD8300,
  700: #8E6B00,
  800: #715500,
  900: #503C00,
  1000: #322500,
  contrast: (
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    1000: $light-primary-text,
  )
);

$yellow-dark-palette: (
  100: #322500,
  200: #503C00,
  300: #715500,
  400: #8E6B00,
  500: #9C7A11,
  600: #BA9115,
  700: #D5A718,
  800: #E1BE52,
  900: #F4DD9A,
  1000: #FFF6DB,
  contrast: (
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    1000: $dark-primary-text,
  )
);

$success-palette: (
  100: #E7F9E7,
  300: #B7FAB7,
  500: #7FDC7F,
  700: #3E843E,
  contrast: (
    100: $dark-primary-text,
    300: $dark-primary-text,
    500: $dark-primary-text,
    700: $light-primary-text,
  )
);

$success-dark-palette: (
  100: #233823,
  300: #427B42,
  500: #8FE08F,
  700: #D5F6D5,
  contrast: (
    100: $light-primary-text,
    300: $dark-primary-text,
    500: $dark-primary-text,
    700: $dark-primary-text,
  )
);

$warn-palette: (
  100: #FFF4E5,
  300: #FFD8A8,
  500: #FFB152,
  700: #AA6109,
  contrast: (
    100: $dark-primary-text,
    300: $dark-primary-text,
    500: $dark-primary-text,
    700: $light-primary-text,
  )
);

$warn-dark-palette: (
  100: #442B13,
  300: #895724,
  500: #FFB266,
  700: #FFDEBD,
  contrast: (
    100: $light-primary-text,
    300: $dark-primary-text,
    500: $dark-primary-text,
    700: $dark-primary-text,
  )
);

$error-palette: (
  100: #FEE9E7,
  300: #FC9C9C,
  500: #C8414F,
  700: #960816,
  contrast: (
    100: $dark-primary-text,
    300: $dark-primary-text,
    500: $light-primary-text,
    700: $light-primary-text,
  )
);

$error-dark-palette: (
  100: #3D1418,
  300: #7F2F33,
  500: #E96C67,
  700: #FCCAC5,
  contrast: (
    100: $light-primary-text,
    300: $light-primary-text,
    500: $dark-primary-text,
    700: $dark-primary-text,
  )
);

$font-family: 'Red Hat Display', sans-serif;
