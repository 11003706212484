// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'sass:map';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

@import './variables';

// TAILWIND
@import 'tailwindcss/utilities';
@import 'tailwindcss/screens';

// Crea tantas variables css como tonos tenga una paleta. Tambien añade el color de contraste para cada tono
@mixin palettes-to-css($palettes) {
  @each $palette-name, $palette in $palettes {
    @each $key, $value in $palette {
      @if $key != contrast {
        #{"--" + $palette-name + "-" + $key}: #{$value};
        #{"--" + $palette-name + "-" + $key + "-contrast"}: #{map.get(map.get($palette, 'contrast'), $key)};
      }
    }
  }
}

// Crea tantas variables css como claves le lleguen
@mixin text-styles-to-css($styles) {
  @each $key, $value in $styles {
    #{"--" + $key}: #{$value};
  }
}

$front-typography: mat.define-typography-config(
  $font-family: $font-family,
);

$light-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($basic-palette, 700),
    accent: mat.define-palette($basic-palette, 700),
    warn: mat.define-palette($error-palette),
  ),
  typography: $front-typography,
));

$light-palettes: (primary: $basic-palette, grey: $grey-palette, success: $success-palette, warn: $warn-palette, error: $error-palette, yellow: $yellow-palette);

@include mat.all-component-themes($light-theme);

.light-theme {
  @include palettes-to-css($light-palettes);
  @include text-styles-to-css((primary-text:$dark-primary-text, secondary-text: $dark-secondary-text, disabled-text: $dark-disabled-text, divider: $dark-divider, focused: $dark-focused));
}

$dark-theme: mat.define-dark-theme((
  color: (
    primary: mat.define-palette($basic-dark-palette, 700),
    accent: mat.define-palette($basic-dark-palette, 700),
    warn: mat.define-palette($error-dark-palette),
  ),
  typography: $front-typography,
));

$dark-palettes: (primary: $basic-dark-palette, grey: $grey-dark-palette, success: $success-dark-palette, warn: $warn-dark-palette, error: $error-dark-palette, yellow: $yellow-dark-palette);

.dark-theme {
  @include mat.all-component-colors($dark-theme);
  @include palettes-to-css($dark-palettes);
  @include text-styles-to-css((primary-text:$light-primary-text, secondary-text: $light-secondary-text, disabled-text: $light-disabled-text, divider: $light-divider, focused: $light-focused));
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-family;
  font-style: normal;
}

// Toast styles
@import '~ngx-toastr/toastr';
@import 'projects/common/styles/fonts'; // Aqui para que exporte los mixins de fonts

// Material rewriting
[class*='theme'] {
  @import 'projects/common/styles/components/background';
  @import 'projects/common/styles/components/accordion';
  @import 'projects/common/styles/components/buttons';
  @import 'projects/common/styles/components/dialogs';
  @import 'projects/common/styles/components/inputs';
  @import 'projects/common/styles/components/links';
  @import 'projects/common/styles/components/navbar';
  @import 'projects/common/styles/components/table';
  @import 'projects/common/styles/components/toaster';
  @import 'projects/common/styles/components/icons';
  @import 'projects/common/styles/components/others';
}

@import 'projects/common/styles/helpers';
