// Estilos barra scroll

div ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--grey-400);
}

div ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: var(--grey-200);
}

div {
  scrollbar-color: var(--grey-400) var(--grey-200);
}

// Toolbar
.mat-drawer,
.mat-toolbar {
  background-color: var(--grey-50);
}

// Fondo
.mat-drawer-container {
  background-color: var(--grey-100);
}

// Paginador
.mat-paginator {
  background-color: var(--grey-100);
  color: var(--primary-text);

  &-container {
    padding: 0;
  }

  .mat-form-field-wrapper {
    padding-bottom: 10px;
  }
}

// Necesario para evitar el que icono de captcha este por debajo del footer
.grecaptcha-badge {
  bottom: 48px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--primary-text);
  box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
