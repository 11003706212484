.mat-button-base {
  font-size: 16px;
  line-height: 21px !important; // Important necesario: la clase de mat-stroked lo pisa sin el.
  letter-spacing: 0.04em !important;
  border-radius: 40px !important;
}

.mat-flat-button,
.mat-stroked-button {
  padding: 8px 20px !important;
}

.mat-flat-button {
  border-radius: 30px !important;
}

.mat-flat-button--black {
  // caso especial, button de 404
  & .mat-stroked-button.mat-primary {
    color: var(--primary-600-contrast);
  }
}

.mat-mini-fab,
.mat-button-base,
.mat-mini-fab.mat-accent {
  background: none;
  box-shadow: none;
}

// Efecto hover
.mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
.mat-stroked-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
  opacity: 0.2;
  background-color: var(--primary-500);
}
