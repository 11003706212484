.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none;
}

.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.mat-expansion-panel-header {
  padding: 0;
}
