@import '../mixins/fonts';

/* stylelint-disable property-no-vendor-prefix */
// Oculta las flechas de los inputs number en Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Oculta las flechas de los inputs number en Firefox
input[type='number'] {
  -moz-appearance: textfield;
}
/* stylelint-enable property-no-vendor-prefix */

// Cambia color cursor cuando error en input
.mat-form-field-invalid .mat-input-element,
.mat-form-field.mat-warn .mat-input-element {
  caret-color: var(--primary-700);
}

// Petición de UX de que los campos disabled tengan el fondo gris
.mat-form-field-disabled {
  & .mat-form-field-outline div {
    background-color: var(--grey-200);
  }

  & .mat-input-element:disabled {
    color: var(--grey-700);
    opacity: 1;
  }
}

// cambio el calor de todas las labels
mat-label {
  color: var(--grey-900);
}

.form-container__form-field {
  @include label;

  overflow: hidden;

  & mat-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & a {
    margin: 8px 0;
  }
}

.mat-form-field {
  &-wrapper {
    padding-bottom: 20px;
  }
}

// da altura al label, esté vacío o no
.select-label,
.mat-label {
  height: 18px;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0;
}

// Para que los imputs tengan el alto requerido
.mat-form-field-infix {
  width: auto;
}

.mat-form-field-flex > .mat-form-field-infix {
  padding-top: 0.1em;
  padding-bottom: 0.35em;
}

.mat-form-field-infix > .mat-datepicker-input {
  padding: 0;
}

// iguala alto de inputs fecha
.datepicker-h > .mat-form-field-wrapper {
  padding-bottom: 0;
  font-size: 0.875rem;

  & .mat-form-field-outline {
    height: 32px !important;
    padding-bottom: 0.35em;
  }
}

// Iguala los inputs de fecha con el ancho del icono
.mat-form-field-suffix .mat-icon-button {
  margin-right: 4px;
  width: 0 !important;
  height: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: var(--grey-600);
}

// Sin borde para el deshabilitado
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: transparent;
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
  width: 1.2em;
  height: 1.2em;
  padding: 0;
}

// Tamaño radios
.mat-radio-container {
  height: 12px;
  width: 12px;
}

.mat-radio-outer-circle {
  height: 12px;
  width: 12px;
}

.mat-radio-inner-circle {
  height: 12px;
  width: 12px;
}

.mat-radio-button .mat-radio-ripple {
  height: 24px;
  width: 24px;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
}
